.Offers__container {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.Offer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    background-color: #003385;
    border-radius: 10px;
    padding: 10px 20px;
    flex: 1;
}

.Offers__title {
    color: #FFF;
    text-align: center;
    font-family: "Marko One";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.Offer__container {
    border-radius: 10px;
    border: 1px solid #003385;
    background: #00296B;
    box-shadow: 10px 10px 4px 0px rgba(0, 41, 107, 0.70);
    width: 348px;
    height: 348px;
}

.Offer__title {
    color: #FFF;
    font-family: "Orelega One";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.Offer__details__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #FDC500;
    font-family: "Zilla Slab";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
}

.Offer__container > img {
    object-fit: scale-down;
    height: 100%;
     width: 100%;
}


@media (max-width: 1310px) {
    .Offers__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .Offer {
        width: 50%;
    }

    .Offer__container {
        width: 302px;
        height: 307px;
    }
}

@media (max-width: 768px) {
    .Offers__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .Offer {
        width: 80%;
    }

    .Offer__container {
        width: 252px;
        height: 307px;
        padding: 10px 10px;
    }
}

@media (max-width: 375px) {
    .Offers__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .Offer {
        width: 80%;
    }

    .Offer__container {
        width: 80%;
        height: 307px;
        padding: 10px 10px;
    }
}