.bannerHome {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bannerHome__container_left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    margin-top: 30px;
}

.BannerTitle{
    color: #FFF;

    text-align: center;
    font-family: "Aoboshi One";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.Button {
    width: 295px;
    height: 58px;
    border-radius: 100px;
    background: #FF9B20;

    color: #FFF;
    font-family: "Averia Serif Libre";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    text-transform: uppercase;
    border: none;
}

.bannerHome__container_right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bannerHome__container_right > img {
    height: 530px;
    width: 690px
}

@media((min-width: 1100px) and (max-width: 1200px)){
    .BannerTitle{
        font-size: 30px;
        max-width: 300px;
    }


}


@media ((min-width: 768px) and (max-width: 1099px)){
    .bannerHome {
        flex-direction: column;
    }

    .bannerHome__container_left {
        width: 100%;
        height: 50%;
    }

    .bannerHome__container_right {
        width: 100%;
        height: 50%;
    }
}

@media (max-width: 767px) {
    .bannerHome {
        flex-direction: column;
        flex-direction: column-reverse;
    }

    .bannerHome__container_left {
        flex-direction: column-reverse;
        width: 100%;
        height: 100%;
    }

    .bannerHome__container_right  {
        width: 100%;
        height: 100%;
    }

    .bannerHome__container_right img {
        object-fit: none;
        width: 100%;
        height: 100%;
    }

    .BannerTitle{
        color: #FFF;
        text-align: center;
        font-family: "Aoboshi One";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

    .Button {
        color: #FFF;
        font-family: "Averia Serif Libre";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        width: 306px;
        height: 48px;
        flex-shrink: 0;
    }

    @media(max-width: 767px){
        .Button {    
            width: 290px;
        }
    }

    @media(max-width: 375px){
        .Button {    
            width: 80%;
        }
    }
}
