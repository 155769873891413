.MobileTimeline{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.DesktopTimeline{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Timeline {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.TimelineTitle {
    color: #FFF;
    text-align: center;
    font-family: "Marko One";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.TimeLineStepLabel__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.TimeLineStepLabel__title {
    color: #FFF;
    text-align: center;
    font-family: "Orelega One";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    height: 27px;
    padding: 0;
    margin: 0;
}

.TimeLineStepLabel__description {
    color: #FDC500;
    text-align: justify;
    font-family: "Zilla Slab";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 320px;
}

@media (max-width: 768px) {
    .TimelineTitle {
        color: #FFF;
        text-align: center;
        font-family: "Marko One";
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
}