.Guarentees {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.Guarentees__title {
    color: #FFF;
    text-align: center;
    font-family: "Marko One";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.Guarentees__container {
    display: flex;
    flex-direction: row;
    gap: 50px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.Guarentee {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 27%;
    padding: 40px 20px;
    height: 390px;
    border-radius: 50px;
    background: #003385;
    box-shadow: 4px 4px 4px 0px rgba(255, 255, 255, 0.10);
}

.Guarentee > img {
    width: 94px;
    height: 116px;
}

.Guarentee__title {
    color: #FFF;
    text-align: center;
    font-family: "Zilla Slab";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.Guarentee__description {
    color: #FDC500;
    text-align: justify;
    font-family: "Zilla Slab";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width: 1310px) {
    .Guarentee {
        /* height: 100%;
        width: 100%; */
        width: 50%;
        flex-basis: auto;
    }

    .Guarentees__container {
        gap: 30px;
    }

    .Guarentees__title {
        font-size: 30px;
    }
}


@media (max-width: 768px) {
    .Guarentees__container {
        gap: 40px;
    }

    .Guarentee {
        width: 80%;
        flex-basis: auto;
    }
}
