.Contact__title {
    color: #FFF;
    text-align: center;
    font-family: "Marko One";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.Contact__description {
    color: #FDC500;
    text-align: center;
    font-family: "Zilla Slab";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 160% */
    margin-top: 60px;
    margin-bottom: 60px;
}

.Contact__panel__containers {
    display: flex;
    width: 100%;
}

.Contact__left__container {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
}

.Contact__left__container__map {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 20px;
}

.Contact__icon img {
    width: 38px;
    height: 38px;
}

.Contact__left__container__description {
    color: #FDC500;
    font-family: "Zilla Slab";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Contact__right__container {
    display: flex;
    width: 50%;
    border-radius: 15px;
    background: #00296B;
    box-shadow: 1px 4px 20px 0px rgba(0, 0, 0, 0.25);
    padding: 45px;
}

.Contact__right__container form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.Contact_button Button {
    margin-top: 20px;
    width: 233px;
    height: 34px;
    flex-shrink: 0;
    border-radius: 100px;
    background: #FF9B20;
}

@media (max-width: 1200px) {
    .Contact__left__container__map {
        width: 80%;
    }

    .Contact__panel__containers {
        flex-direction: column;
        gap: 20px;
    }

    .Contact__left__container {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .Contact__right__container {
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0;
        box-shadow: none;
    }

    .Contact__right__container form {
        width: 80%;
        gap: 4px;
    }
}


@media (max-width: 768px) {
    .Contact__left__container__map {
        width: 80%;
    }
    .Contact__left__container__description {
        color: #FDC500;
        font-family: "Zilla Slab";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .Contact_button Button {
        width: 120px;
    }
}