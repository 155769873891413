.Header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 40px;
}

.Logo {
    height: 98px;
    width: 214px;
    /* margin-left: 150px; */
}

.Navbar{
    display: flex;
    align-items: center;
    /* margin-right: 150px; */
}

.Menu  {
    display: flex;
    padding: 0;
    gap: 40px;
}

.Menu > li {
    color: white;
    list-style-type: none;
}

.Menu > li > a {
    text-decoration: none;
    color: #FFF;
    font-family: "Averia Serif Libre";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media (max-width: 1000px) {
    .Logo {
        margin-left: 0;

    }

    .Header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;

    }

    .Navbar {
        margin-right: 0;
    }

    .Menu  {
        gap: 20px;
    }

    .Menu > li > a {
        text-decoration: none;
        color: white;
        font-family: "Averia Serif Libre";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}


@media (max-width: 375px){
    .Menu  {
        display: flex;
        padding: 0;
        gap: 10px;
    }

    .Menu > li > a {
        font-size: 12px;
    }
    
    
}