.Footer {
    display: flex;
    flex-direction: column;
}

.Footer__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Footer__logo {

}

.Footer__info {
    
}

.Footer__info--text {
    color: #FDC500;
    font-family: "Zilla Slab";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}