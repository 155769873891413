.App {
  background-color: #00296B;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



