.Quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.Quote form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.Quote__button {
    margin-top: 20px;
}

.Quote__title {
    color: #FFF;
    text-align: center;
    font-family: "Marko One";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.Quote__subtitle {
    color: #FFF;
    text-align: center;
    font-family: "Orelega One";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.Quote__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 60%;
}

.form-control:focus {
    box-shadow: none!important;
  }

  input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

.Quote__button Button {
    width: 120px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 100px;
    background: #FF9B20;
    color: #FFF;
    font-family: "Averia Serif Libre";
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


@media (max-width: 1200px) {
    .Quote__step {
        width: 80%;
    }
}